import React from 'react';
import { Calendar, Mail, Phone, CreditCard, DollarSign, Printer, Bitcoin } from 'lucide-react';

const InvoiceComponent = () => {
    const invoiceData = {
        from: {
            name: 'Ahmed Hmed',
            phone: '+249 915 903 708',
            email: 'ahmed7med23@gmail.com',
        },
        to: {
            name: 'Tareq Ibrahim',
            address: 'Dubai, United Arab Emirates',
        },
        project: 'Swift Project',
        date: 'July 12, 2024',
        invoiceNumber: 'INV-240712-1',
        items: [
            { description: 'Web Development Services', hours: '66:38:41', rate: 50, amount: 3332.26 },
            { description: 'Special Discount', hours: '66:38:41', rate: -40, amount: -2665.81 },
        ],
        usdtAddress: 'TRw1WZ4SHrBN5596J9e4fBH2m5oHACmNXq', // Example USDT address
    };

    const total = invoiceData.items.reduce((sum, item) => sum + item.amount, 0);

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="bg-gradient-to-br from-ortext-orange-100 to-teal-100 min-h-screen flex items-center justify-center p-4">
            <div className="bg-white shadow-2xl rounded-lg overflow-hidden max-w-4xl w-full">
                <div className="bg-gradient-to-r from-yellow-400 to-orange-400 text-white p-6">
                    <h1 className="text-3xl font-bold text-center mb-2">Professional Invoice</h1>
                    <p className="text-center text-white">Web Development Services</p>
                </div>

                <div className="p-6 space-y-6">
                    <div className="flex flex-col md:flex-row justify-between gap-6">
                        <div className="bg-gray-50 p-4 rounded-lg shadow">
                            <h3 className="font-bold text-lg mb-2 text-orange-600">From:</h3>
                            <p className="font-semibold">{invoiceData.from.name}</p>
                            <p className="flex items-center text-gray-600"><Phone size={16} className="mr-2 text-orange-500" /> {invoiceData.from.phone}</p>
                            <p className="flex items-center text-gray-600"><Mail size={16} className="mr-2 text-orange-500" /> {invoiceData.from.email}</p>
                        </div>
                        <div className="bg-gray-50 p-4 rounded-lg shadow">
                            <h3 className="font-bold text-lg mb-2 text-orange-600">To:</h3>
                            <p className="font-semibold">{invoiceData.to.name}</p>
                            <p className="text-gray-600">{invoiceData.to.address}</p>
                        </div>
                    </div>

                    <div className="bg-gray-50 p-4 rounded-lg shadow space-y-2">
                        <p className="flex items-center"><span className="font-bold mr-2 text-orange-600">Project:</span> {invoiceData.project}</p>
                        <p className="flex items-center"><Calendar size={16} className="mr-2 text-orange-500" /> <span className="font-bold mr-2 text-orange-600">Date:</span> {invoiceData.date}</p>
                        <p className="flex items-center"><CreditCard size={16} className="mr-2 text-orange-500" /> <span className="font-bold mr-2 text-orange-600">Invoice Number:</span> {invoiceData.invoiceNumber}</p>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-ortext-orange-600 text-white">
                                    <th className="p-2 text-left">Description</th>
                                    <th className="p-2 text-left">Hours</th>
                                    <th className="p-2 text-left">Rate</th>
                                    <th className="p-2 text-left">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceData.items.map((item, index) => (
                                    <tr key={index} className={item.rate < 0 ? 'text-red-600 bg-red-50' : 'even:bg-gray-50'}>
                                        <td className="p-2 border-t">{item.description}</td>
                                        <td className="p-2 border-t">{item.hours}</td>
                                        <td className="p-2 border-t">${Math.abs(item.rate)}/hour</td>
                                        <td className="p-2 border-t">${Math.abs(item.amount).toFixed(2)}</td>
                                    </tr>
                                ))}
                                <tr className="font-bold bg-ortext-orange-100">
                                    <td colSpan="3" className="p-2 border-t text-right">Total</td>
                                    <td className="p-2 border-t">${total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="text-sm text-gray-600 bg-gray-50 p-4 rounded-lg shadow">
                        <p className="mb-1"><span className="font-bold text-orange-600">Payment Method:</span> USDT (TRC20)</p>
                        <div className="mb-4">
                            <p className="font-bold text-orange-600 mb-1">USDT Payment Address:</p>
                            <p className="bg-ortext-orange-50 p-2 rounded break-all">{invoiceData.usdtAddress}</p>
                        </div>
                        <p className="mb-1"><span className="font-bold text-orange-600">Payment Terms:</span> Due within 5 days</p>
                        <p className="mb-4">Note: A special discount of $40 per hour has been applied to the original price.</p>
                        <div className="flex items-center justify-center text-orange-600 mb-4">
                            <Bitcoin size={24} className="mr-2" />
                            <p className="font-bold">Cryptocurrency Payment Accepted</p>
                        </div>
                        <p className="text-center font-bold text-orange-600">Thank you for your business!</p>
                    </div>

                    <div className="flex justify-center">
                        <button
                            onClick={handlePrint}
                            className="bg-ortext-orange-600 hover:bg-ortext-orange-700 text-white font-bold py-2 px-4 rounded flex items-center transition duration-300"
                        >
                            <Printer size={18} className="mr-2" /> Print Invoice
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceComponent;