import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../services/GeneralServices';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';

function EditUser({ close, fetchUsers, userData: user }) {

    const [userData, setUserData] = useState({
        id: null,
        full_name: '',
        email: '',
        password: '',
        role: 'employee',
        currencies: [],
    });

    const [currencies, setCurrencies] = useState([]);

    const [validation, setValidation] = useState([]);

    const [loading, setLoading] = useState(false);

    const service = new GeneralServices();


    const handleEditEmployee = async () => {
        setLoading(true)
        setValidation([])
        try {
            const res = await service.post('users/update', userData)

            showSuccessToast('تمت تعديل الموظف بنجاح')
            fetchUsers();
            close()
        } catch (error) {

            if (error.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            setLoading(false)
        }
    }


    const fetchCurrencies = async () => {
        try {
            const res = await service.get('currencies')
            setCurrencies(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        setUserData(user)
        fetchCurrencies()
    }, [])




    return <div className="p-1">

        <div className='mb-2'>
            <Input
                type="text"
                value={userData.full_name}
                onChange={(e) => setUserData({ ...userData, full_name: e.target.value })}
                placeholder="اسم الموظف"
                borderStyle="border border-gray-300"
            />
            {validation?.full_name && <p className='text-[10px] m-1 text-rose-700'>{validation?.full_name?.[0]}</p>}
        </div>

        <div className='mb-2'>
            <Input
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                placeholder="البريد الالكتوني"
                borderStyle="border border-gray-300"
            />

            {validation?.email && <p className='text-[10px] m-1 text-rose-700'>{validation?.email?.[0]}</p>}
        </div>

        <div className='mb-2'>
            <Input
                type="text"
                value={userData.password}
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                placeholder="كلمه السر"
                borderStyle="border border-gray-300"
            />

            {validation?.password && <p className='text-[10px] m-1 text-rose-700'>{validation?.password?.[0]}</p>}
        </div>
        <div className='flex gap-2'>
            <p onClick={() => setUserData({ ...userData, role: 'employee' })} className={`${userData.role == 'employee' ? 'bg-green-500 text-white' : ''} px-2 cursor-pointer rounded-full`}>موظف</p>
            <p onClick={() => setUserData({ ...userData, role: 'admin' })} className={`${userData.role == 'admin' ? 'bg-green-500 text-white' : ''} px-2 cursor-pointer rounded-full`}>ادمن</p>

        </div>
        <div className='mt-2'>
            {userData.role == 'employee' && <div>
                <CurrencyBadge items={currencies} userData={userData} setUserData={setUserData} />
            </div>}

            {validation?.currencies && <p className='text-[10px] m-1 text-rose-700'>{validation?.currencies?.[0]}</p>}

        </div>
        <Button loading={loading} onClick={handleEditEmployee} text='تعديل' />
    </div>

}


const CurrencyBadge = ({ items, userData, setUserData }) => {


    const toggleUserCurrency = (item) => {

        if (userData.currencies.includes(item.id)) {
            setUserData({ ...userData, currencies: userData.currencies.filter(x => x !== item.id) })
        } else {
            setUserData({ ...userData, currencies: [...userData.currencies, item.id] })
        }
    }

    return (

        <div className='flex overflow-x-scroll gap-2 p-2' style={{ scrollbarWidth: 'thin', scrollbarColor: 'gray transparent' }}>

            {items.map((item, index) => (
                <p
                    key={index}
                    onClick={() => toggleUserCurrency(item)}
                    className={`cursor-pointer flex items-center gap-2 rounded-full px-2 py-1 ${userData.currencies.includes(item.id) ? 'text-white bg-black' : 'border px-2'
                        }`}
                    style={{
                        whiteSpace: 'nowrap', // Ensures text remains on a single line
                        transition: 'all 0.3s ease', // Smooth visual interaction on change
                    }}
                >
                    <div
                        className={`w-[10px] h-[10px] rounded-full ${item.warning_balance > item.balance ? 'bg-red-500' : 'bg-green-500'
                            }`}
                    ></div>
                    {item.name}
                </p>
            ))}
        </div>
    )
}


export default EditUser