import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import BottomSheet from '../../components/BottomSheet';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import { formatDate } from '../../utils/utility';

function ExpensesPage() {


    const [open, setOpen] = useState(false);

    const [expensCategories, setExpensCategories] = useState([])

    const [categoryId, setCategoryId] = useState(null)

    const [expenses, setExpenses] = useState([]);

    const service = new GeneralServices();


    const fetchExpenses = async () => {
        try {
            const res = await service.get(`expenses/${categoryId ? categoryId : 'all'}`)
            setExpenses(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const close = () => setOpen(!open)


    const fetchExpensCategories = async () => {
        try {
            const res = await service.get(`expens_categories`)
            setExpensCategories(res)

        } catch (error) {
            showErrorToast('عفوا يوجد خطاء')
        }
    }




    useEffect(() => {
        fetchExpensCategories()
        fetchExpenses()

    }, [])



    useEffect(() => {
        fetchExpenses()

    }, [categoryId])


    return (
        <>
            <BottomSheet title='اضافة منصرف' isOpen={open} onClose={() => setOpen(false)} >
                <AddExpensesCard fetchExpenses={fetchExpenses} close={close} />
            </BottomSheet>

            <div className='px-[8px]'>

                <div className='flex justify-between mx-[8px'>
                    <h1 className='text-lg font-semibold '>المنصرفات</h1>
                    <div onClick={() => setOpen(true)} className='bg-black rounded-md select-none cursor-pointer text-white px-2 py-1 flex gap-2'>
                        <img src='/assets/icons/add.png' alt='' className='w-6 h-6' />
                        <p>اضافة</p>
                    </div>
                </div>

                <div

                    className=' flex overflow-x-scroll gap-2 p-2'>
                    <div
                        onClick={() => setCategoryId(null)}
                        style={{
                            whiteSpace: 'nowrap', // لضمان بقاء النص في سطر واحد
                            transition: 'all 0.3s ease', // لتحسين التفاعل البصري عند التغيير
                        }}
                        className={categoryId === null ? 'bg-black border px-2 py-1 max-w-max rounded-full cursor-pointer' : 'bg-white text-black border  px-2 py-1 max-w-max rounded-full cursor-pointer'}>
                        <h1 className=' text-[14px] '>الكل</h1>
                    </div>
                    {expensCategories && expensCategories.map((item, index) => (

                        <div
                            onClick={() => setCategoryId(item.id)}
                            style={{
                                whiteSpace: 'nowrap', // لضمان بقاء النص في سطر واحد
                                transition: 'all 0.3s ease', // لتحسين التفاعل البصري عند التغيير
                            }}
                            key={index}
                            className={categoryId === item.id ? 'bg-black border px-2 py-1 max-w-max rounded-full cursor-pointer' : 'bg-white text-black border px-2 py-1 max-w-max rounded-full cursor-pointer'}>
                            <h1 className=' text-[14px] '>{item.name}</h1>
                        </div>
                    ))}
                </div>


                {expenses && expenses.map((item, index) => <ExpensCard fetchExpenses={fetchExpenses} key={item.id} data={item} />)}
            </div>
        </>
    )
}

const ExpensCard = ({ data, fetchExpenses }) => {

    const service = new GeneralServices()

    const { user } = JSON.parse(localStorage.getItem('user'))

    const [expens, setExpens] = useState(data)


    const changeStatus = async (status) => {
        const { id } = data

        try {
            const response = await service.post('expensesSTatus', { status, id })

            setExpens({ ...data, status: response.status })
        } catch (error) {

            if (error?.state === 400) {
                showErrorToast(error?.message)
            }
            else {

                showErrorToast('عفوا يوجد خطاء')
            }
        } finally {
            fetchExpenses()
        }

    }




    return <div className='shadow-lg bg-white mt-2 rounded-lg'>


        <div className='flex items-center justify-between  p-2'>

            <div className='flex gap-2 items-center'>
                <img className='h-8 w-8 rounded-full' src={expens?.currency?.icon} />
                <div>
                    <p>{expens?.currency?.name}</p>
                    <p className='text-[11px]'>{expens?.safe?.name}</p>
                </div>
            </div>
            <div className='items-end flex flex-col'>
                <div className='flex items-baseline gap-1'>

                    <span className='text-[10px]'> {expens?.currency?.symbol}</span>
                    <h1 className='text-2xl font-bold'>{expens?.amount}</h1>
                </div>
                <div className='flex gap-1'>

                    <div className='bg-black px-2 py-1 max-w-max rounded-full'>
                        <h1 className='text-white text-[10px] '>{expens?.expenes_category?.name}</h1>
                    </div>

                    <div className={`${expens?.status === 0 ? 'bg-yellow-400' :
                        expens?.status === 1 ? 'bg-greenColor' : 'bg-red-700'
                        } select-none cursor-pointer px-2 py-1 max-w-max rounded-full`}>
                        <h1 className='text-white text-[10px] '>
                            {expens?.status === 0 ? 'انتظار' : expens?.status === 1 ? 'تمت' : 'رفضت'}
                        </h1>
                    </div>
                </div>
            </div>
        </div>

        <hr className='my-1  ' />
        <div className='p-2'>
            <p>ملاحظه</p>
            <p className='text-gray-400'>{data?.note}</p>
        </div>


        <div className='flex justify-between text-[12px] bg-gray-100  p-2'>
            <div className='flex gap-1 '>
                <p>بواسطه </p>
                <p className='font-bold'> {data?.user?.full_name}</p>
            </div>
            <p> {formatDate(data.created_at)}</p>
        </div>
        {user?.role == 'admin' && expens.status == 0 && <>


            <hr />
            <div className='flex gap-3 text-[12px] bg-gray-50  p-2'>

                <div onClick={() => changeStatus('accept')} className='bg-greenColor  select-none cursor-pointer px-2 py-1 max-w-max rounded-full'>
                    <h1 className='text-white text-md  '>
                        موافقه
                    </h1>
                </div>
                <div onClick={() => changeStatus('reject')} className='bg-rose-600  select-none cursor-pointer px-2 py-1 max-w-max rounded-full'>
                    <h1 className='text-white text-md  '>
                        رفض
                    </h1>
                </div>
            </div>
        </>
        }
    </div >
}

const AddExpensesCard = ({ fetchExpenses, close }) => {

    const [expensCategories, setExpensCategories] = useState([])
    const [currencies, setCurrencies] = useState([])

    const [expensData, setExpensData] = useState({
        amount: '',
        note: '',
        expenes_category_id: 1,
        safe_id: 1,
        currency_id: null,
    });


    const [validation, setValidation] = useState([]);

    const service = new GeneralServices();


    const handleAddExpens = async () => {
        setValidation([])
        try {
            const res = await service.post('expenses', expensData)
            console.log('ahmed ')
            showSuccessToast('تمت اضافة المنصرفات بنجاح')
            fetchExpenses();
            close()
        } catch (error) {

            if (error.validationData) {
                setValidation(error.validationData)
            }
            if (error.state == 400) {
                showErrorToast(error.message)
            }
        }
    }


    const fetchExpensCategories = async () => {
        try {
            const res = await service.get('expens_categories')
            setExpensCategories(res)
            setExpensData({ ...expensData, expenes_category_id: res.id })
        } catch (error) {
            showErrorToast('عفوا يوجد خطاء')
        }
    }

    const fetchCurrencies = async () => {
        try {
            const res = await service.get('currencies')
            setCurrencies(res)
        } catch (error) {
            showErrorToast('عفوا يوجد خطاء')
        }
    }


    useEffect(() => {
        fetchCurrencies()
        fetchExpensCategories()
    }, [])



    return <div className="p-1">
        <div className='mb-2'>
            <Input
                type="number"
                value={expensData.amount}
                onChange={(e) => setExpensData({ ...expensData, amount: e.target.value })}
                placeholder="المبلغ"
                borderStyle="border border-gray-300"
            />
            {validation?.amount && <p className='text-[10px] m-1 text-rose-700'>{validation?.amount?.[0]}</p>}
        </div>
        <div className='mb-2'>
            <Input
                type="text"
                value={expensData.note}
                onChange={(e) => setExpensData({ ...expensData, note: e.target.value })}
                placeholder="ملاحظات"
                borderStyle="border border-gray-300"
            />
            {validation?.note && <p className='text-[10px] m-1 text-rose-700'>{validation?.note?.[0]}</p>}
        </div>
        <div className="form-group">
            <p className='font-light' for="expenes_category_id">التصنيفات</p>
            <select id='expenes_category_id' onChange={(e) => setExpensData({ ...expensData, expenes_category_id: e.target.value })} className="form-control" >
                <option value="">اختر التصنيف</option>
                {expensCategories.map((item, index) => <option key={index} selected={expensData.expenes_category_id == item.id} value={item.id}>{item.name}</option>)}
            </select>

            {validation?.expenes_category_id && <p className='text-[10px] m-1 text-rose-700'>{validation?.expenes_category_id?.[0]}</p>}
        </div>
        <div className='flex gap-2 my-4'>
            <div className='flex overflow-x-scroll gap-2 p-2' style={{ scrollbarWidth: 'thin', scrollbarColor: 'gray transparent' }}>
                {currencies.map((item, index) => (
                    <p
                        key={index}
                        onClick={() => setExpensData({ ...expensData, currency_id: item.id })}
                        className={`cursor-pointer flex items-center gap-2 rounded-full px-2 py-1 ${expensData.currency_id === item.id ? 'text-white bg-black  ' : 'border px-2'}`}
                        style={{
                            whiteSpace: 'nowrap', // لضمان بقاء النص في سطر واحد
                            transition: 'all 0.3s ease', // لتحسين التفاعل البصري عند التغيير
                        }}
                    >
                        <div className={`w-[10px] h-[10px] rounded-full ${item.warning_balance > item.balance ? 'bg-red-500' : 'bg-green-500'}`}></div>
                        {item.name}
                    </p>
                ))}

            </div>

        </div>
        {validation?.currency_id && <p className='text-[10px] m-1 text-rose-700'>{validation?.currency_id?.[0]}</p>}

        <Button onClick={handleAddExpens} text='اضافة' />
    </div>
}


const currencyBadge = ({ data, onClick }) => {
    return <p onClick={onClick} className=' cursor-pointer  rounded-full text-white bg-black px-2 py-[1px] font-bold'>درهم</p>
}
export default ExpensesPage