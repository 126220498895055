import React, { useContext, useEffect, useState } from 'react'
import BottomSheet from '../../components/BottomSheet';
import GeneralServices from '../../services/GeneralServices';
import WalletCard from './Components/WalletCard';
import { AuthContext } from '../../contexts/AuthContextProvider';

function WalletPage() {





    const [currencies, setCurrencies] = useState([]);

    const { fetch } = useContext(AuthContext)

    const service = new GeneralServices();


    const fetchCurrencies = async () => {
        try {
            const res = await service.get('currencies')
            setCurrencies(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const close = () => setOpen(!open)

    useEffect(() => {
        fetchCurrencies()
    }, [fetch == 'currency/add_balance'])







    return (
        <div>
            {currencies.map((data, index) => <WalletCard data={data} key={index} />)}
        </div>
    )
}



export default WalletPage