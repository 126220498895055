import React, { useState } from 'react'
import Input from '../../../components/Input'
import Switch from '../../../components/Switch'
import Button from '../../../components/Button'
import GeneralServices from '../../../services/GeneralServices'
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils'

function TransactionEdit({ transaction, fetchTransactions, close }) {


    const [TransactionData, setTransactionData] = useState({
        transaction_id: transaction?.id,
        from_balance: transaction?.from_balance,
        to_balance: transaction?.to_balance,
        note: transaction?.note
    })


    const [rateFlag, setRateFlag] = useState(false)
    const [rate, setRate] = useState(1)

    const service = new GeneralServices()

    const handleEdit = async () => {
        try {
            const res = await service.post(`transaction/${transaction.id}/edit`, TransactionData)
            showSuccessToast('تم تعديل المعاملة بنجاح')
            fetchTransactions()
            close()
        } catch (error) {
            console.log('error', error)
            showErrorToast(error.message)
        }
    }

    const changeFromBalance = (e) => {
        const newToAmount = parseFloat(e.target.value) || 0; // Fallback to 0 if input is empty or invalid




        if (rateFlag) {
            setTransactionData((prevTransaction) => ({
                ...prevTransaction,
                from_balance: newToAmount,
                to_balance: newToAmount * rate
            }));
        } else {
            setTransactionData((prevTransaction) => ({
                ...prevTransaction,
                from_balance: newToAmount
            }));
        }
    }


    const changeToBalance = (e) => {
        const newToAmount = parseFloat(e.target.value) || 0; // Fallback to 0 if input is empty or invalid
        if (rateFlag) {
            setTransactionData((prevTransaction) => ({
                ...prevTransaction,
                to_balance: newToAmount,
                from_balance: newToAmount / rate
            }));
        } else {
            setTransactionData((prevTransaction) => ({
                ...prevTransaction,
                to_balance: newToAmount
            }));
        }
    }

    const handleRateChange = (e) => {
        const newRate = parseFloat(e.target.value) || 1; // Fallback to 1 if input is empty or invalid
        setRate(newRate);
        if (!rateFlag) return;
        setTransactionData((prevTransaction) => ({
            ...prevTransaction,
            to_balance: prevTransaction.from_balance * newRate
        }));

    }



    return <>

        <div className='mb-4'>
            <div className='flex items-center gap-2 p-1'>
                <img className='rounded-full w-4 h-4' src={transaction?.from_currency?.icon} alt="administrator-male--v1" />
                <p>المبلغ {transaction?.from_currency?.name} </p>
            </div>
            <Input
                label="المبلغ"
                placeholder={"المبلغ"}
                value={TransactionData?.from_balance}
                onChange={(e) => changeFromBalance(e)}
            />
        </div>
        <div className='flex items-center gap-2 justify-between'>
            <p>الريت في العملة</p>
            <div className='flex items-center gap-2'>
                <Switch checked={rateFlag} onChange={() => setRateFlag(!rateFlag)} />
                {rateFlag && <div className='w-[100px]'>
                    <Input onChange={(e) => handleRateChange(e)} label="المبلغ" placeholder={"المبلغ"} value={rate} />
                </div>}
            </div>
        </div>
        <div>
            <div className='flex items-center gap-2 p-1'>
                <img className='rounded-full w-4 h-4' src={transaction?.to_currency?.icon} alt="administrator-male--v1" />
                <p>المبلغ {transaction?.to_currency?.name} </p>
            </div>
            <Input
                label="المبلغ"
                placeholder={"المبلغ"}
                value={TransactionData?.to_balance}

                onChange={(e) => changeToBalance(e)}
            />
        </div>

        <div className='mt-4'>
            <textarea
                className='w-full border p-2 rounded-lg h-32'
                placeholder='ملاحظات'
                onChange={(e) => setTransactionData((prevTransaction) => ({ ...prevTransaction, note: e.target.value }))}
                value={TransactionData?.note}
            />
        </div>


        <div>
            <Button onClick={handleEdit} text={'تعديل'} className='border px-3  rounded-full' />
        </div>
    </>
}

export default TransactionEdit