import React, { useState } from 'react';

const BottomSheet = ({ isOpen, onClose, children, title = 'الخيارات' }) => {
    return (
        <>
            {isOpen && (
                <div className={`fixed inset-0 flex items-end justify-center z-50 `} style={{ backdropFilter: 'blur(4px)' }}>
                    <div className={`fixed inset-0 bg-black opacity-50 `} onClick={onClose}></div>
                    <div className="bg-white w-full  rounded-t-lg p-4 shadow-lg transform transition-transform duration-3000 ease-in-out">
                        <div className={`flex justify-between items-center border-b pb-1 mb-4 `}>
                            <h1 className="text-md font-medium">{title}</h1>
                            <button onClick={onClose}>اغلاق</button>
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomSheet;
