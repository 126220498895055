import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import GeneralServices from '../../services/GeneralServices';
import { showErrorToast } from '../../utils/toastUtils';
import BalanceCard from './Component/BalanceCard.';
import ListTile from '../../components/ListTile';




function SettingsPage() {

    const { user, setUser } = useContext(AuthContext)

    const [safes, setSafes] = useState([])

    const [balances, setBalances] = useState([])
    const [labels, setLabels] = useState([])

    const service = new GeneralServices();

    const navigate = useNavigate();


    const fetchMySafes = async () => {
        try {
            const res = await service.get('safes/my')

            setSafes(res)

            const b = res.map(safe => safe.balance)

            const l = res.map(safe => safe.name)

            setLabels(l)

            setBalances(b)
        } catch (error) {
            console.log('error', error)
        }
    }



    const handleLogOut = async () => {
        try {
            const res = await service.post('logout')

            localStorage.clear();
            setUser(null);
            navigate('/login');

        } catch (error) {
            console.log('error', error)
            showErrorToast('عفوا يوجد خطاء في عملية تسجيل الخروج')
        }
    }

    useEffect(() => {
        fetchMySafes()
    }, [])


    return (
        <div>
            <div>
                <Link>التحويلات</Link>
            </div>
            <div>
                <BalanceCard safes={safes} balances={balances} labels={labels} />
            </div>
            <div className='flex justify-center mt-2 cursor-pointer border p-2 rounded-full' onClick={handleLogOut}>
                <p>تسجيل خروج</p>
            </div>
            <div className='mt-1'>
                <ListTile title="الاعدادات" />
            </div>

        </div>
    )
}


export default SettingsPage