import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../services/GeneralServices'
import DoughnutChart from '../../../components/DoughnutChart'
import { formatNumber } from '../../../utils/utility';


const safe_colors = ['#567df4', '#ffc700', '#22215b'];

function BalanceCard({ safes, balances, labels }) {



    const data = {
        labels: labels,
        datasets: [
            {
                label: 'بيانات الخزنه',
                data: balances,
                backgroundColor: safe_colors,
            },
        ],
    };



    return (
        <div className='rounded-xl mt-2 shadow-md p-2  py-3'>
            <p className='text-md font-medium'>تفاصيل الرصيد</p>
            <div className=' flex justify-between items-center'>
                <div>
                    {safes.map((safe, index) => (
                        <div className='text-center m-2 border p-2 rounded-xl'>
                            <h1 className='text-sm text-gray-400 flex  items-center gap-1'>
                                <div className={`h-2 w-2 rounded-full bg-[${safe_colors[index]}]`}
                                    style={{ backgroundColor: safe_colors[index] }}></div>
                                {safe.name}</h1>
                            <h1 className='text-[#24235c] text-2xl font-bold'>{formatNumber(safe.balance)}</h1>
                        </div>
                    ))}
                </div>

                <div className='w-[50%]' style={{ height: '200px' }}>
                    <DoughnutChart data={data} />
                </div>
            </div>
        </div>
    )
}

export default BalanceCard