import React, { useContext, useEffect, useState } from 'react'
import GeneralServices from '../services/GeneralServices';
import Input from '../components/Input';
import { showErrorToast } from '../utils/toastUtils';
import { AuthContext } from '../contexts/AuthContextProvider';
import { useNavigate } from 'react-router-dom';




function Login() {

    const navigate = useNavigate()

    const { user, setUser } = useContext(AuthContext);

    const [userData, setUserData] = useState({
        email: null,
        password: null
    });

    const [validation, setValidation] = useState([]);


    const [isLoggedIn, setIsLoggedIn] = useState(false);


    const service = new GeneralServices();

    const handleLogin = async () => {

        setValidation([])
        setIsLoggedIn(true)
        try {
            const res = await service.post('login', userData)
            await localStorage.setItem('user', JSON.stringify(res))
            setUser(res)

            navigate('/wallet')
        } catch (error) {

            if (error.state == 401) {
                showErrorToast(error.message)
            }

            if (error.validationData) {
                setValidation(error.validationData)
            }

        }
        finally {
            setIsLoggedIn(false)
        }
    }


    // if user login go to wallet
    useEffect(() => {
        if (user) {
            navigate('/wallet')
        }

    }, [user])


    // check if user is logged in if user data exist in local storage or context then go to wallet 
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'))
        if (userData) {
            setUser(userData)
            navigate('/wallet')
        } else {
            setUser(null)
        }
    }, [])


    return (
        <div className='w-full h-screen flex-col slide justify-center'>


            <div className='flex items-center justify-center pt-[15%]' >
                <img className='w-1/3 rounded-full' src='/assets/logo.png' />

            </div>
            <div className='text-center mt-[5%] '>
                <h1 className='font-bold text-2xl text-white'>تسجيل دخول</h1>
            </div>

            <div className='flex items-center justify-center  mt-[15%]'>
                <div className='flex items-center justify-center shadow-lg bg-white w-[80%] p-4 rounded-lg'>

                    <div className='mx-[10%] w-full '>
                        <div className=' justify-center w-[100%]'>

                            <Input
                                placeholder={'البريد الالكتروني'}
                                value={userData.email}
                                type={'email'}
                                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                error={validation?.email?.[0]}
                            />

                            <Input
                                placeholder={'كلمه السر'}
                                value={userData.password}
                                type={'password'}
                                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                error={validation?.password?.[0]}
                            />
                        </div>
                        <div className='flex justify-center mt-2'>
                            <button onClick={handleLogin} on className='border cursor-pointer border-black w-[80%] p-2 rounded-lg mt-2 text-center bg-black font-semibold text-white' style={{ width: '100%' }}>
                                <p>
                                    {isLoggedIn ? 'جاري تسجيل الدخول...' : 'تسجيل دخول'}

                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login