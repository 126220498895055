import React, { useContext, useState } from 'react';
import GeneralServices from '../../../services/GeneralServices';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import BottomSheet from '../../../components/BottomSheet';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { formatNumber } from '../../../utils/utility';
import Switch from '../../../components/Switch';
import TransactionEdit from './TransactionEdit';
import { AuthContext } from '../../../contexts/AuthContextProvider';

const TransactionCard = ({ transaction, fetchTransactions }) => {




    const [openTransaction, setTransaction] = useState(false)
    const [openEdit, setOpedEdit] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)

    const [loading, setLoading] = useState('')

    const service = new GeneralServices()


    const handleAprove = () => setTransaction(true)
    const handleDelete = async () => {
        setLoading('delete')
        try {
            const res = await service.post(`transaction/${transaction.id}/delete`)
            console.log('res', res)
            showSuccessToast('تم المسح بنجاح')
            fetchTransactions()
        } catch (error) {
            console.log('error', error)

        } finally {
            setLoading('')
        }
    }



    return (
        <>
            {openTransaction && <BottomSheet title='تفاصيل العملية' isOpen={transaction} onClose={() => setTransaction(false)} >
                <TransactionDetails fetchTransactions={fetchTransactions} close={() => setTransaction(false)} transaction={transaction} />
            </BottomSheet>}

            {openEdit && <BottomSheet title='تعديل العملية' isOpen={openEdit} onClose={() => setOpedEdit(false)} >
                <TransactionEdit fetchTransactions={fetchTransactions} close={() => setOpedEdit(false)} transaction={transaction} />
            </BottomSheet>}

            {deleteDialog && <BottomSheet title='تاكيد الحزف' isOpen={deleteDialog} onClose={() => setDeleteDialog(false)} >
                <div className='grid grid-cols-2 gap-2'>
                    <Button color='red' onClick={() => setDeleteDialog(false)} text='لا' />

                    <Button loading={loading == 'delete'} color='green' onClick={handleDelete} text={loading == 'delete' ? 'جارى المسح' : 'نعم'} />
                </div>

            </BottomSheet>}


            <div className='shadow-lg bg-white mt-2 rounded-xl p-2'>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <img src={transaction?.from_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                        <div>
                            <p>{transaction?.from_currency?.name}</p>
                            <p className='text-[16px] font-bold'>{formatNumber(transaction?.from_balance)}</p>
                            {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={transaction?.to_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                        <div>
                            <p>{transaction?.to_currency?.name}</p>
                            <p className='text-[16px] font-bold'>{formatNumber(transaction?.to_balance)}</p>
                            {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                        </div>
                    </div>
                </div>
                <hr className='my-2' />
                <div>
                    <p className='text-gray-400'>{transaction?.note}</p>
                </div>
                <hr className='my-2' />
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between'>
                        <div className='flex gap-1 items-center'>
                            <div className='flex items-center gap-2'>
                                <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/12B886/administrator-male--v1.png" alt="administrator-male--v1" />
                                <p className='text-gray-500 '>بواسطه</p> <p className='text-gray-500 font-bold'>{transaction?.creator?.full_name.split(' ').slice(0, 2).join(' ')}</p>
                            </div>
                        </div>

                        <div className='flex gap-1 items-center '>
                            <div className='flex items-center gap-2'>
                                <img width="24" height="24" src="https://img.icons8.com/material/24/12B886/administrator-male--v1.png" alt="administrator-male--v1" />

                                <p className='text-gray-500 '>المنفذ</p> <p className='text-gray-500 font-bold'>{transaction?.approver?.full_name.split(' ').slice(0, 2).join(' ')}</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-between'>


                        <div className='flex gap-1 items-center '>
                            <div className='flex items-center gap-2'>
                                <img width="24" height="24" src="https://img.icons8.com/pastel-glyph/64/12B886/calendar.png" alt="administrator-male--v1" />

                                <div>
                                    <TransactionDate transaction={transaction} />
                                </div>
                            </div>
                        </div>


                        {/* <button onClick={() => handleAprove(transaction?.id)} className='border px-3  rounded-full'>تنفيذ</button> */}

                        <div className='flex gap-3'>
                            {transaction.status === 0 ? <>

                                <button onClick={handleAprove} className='border text-[10px] h-[20px] px-3 bg-green-500 text-white rounded-full'>تنفيذ</button>
                                <button onClick={() => setOpedEdit(true)} className=' text-[10px] bg-yellow-500 text-white h-[20px] px-3  rounded-full'>تعديل</button>
                                <button onClick={() => setDeleteDialog(true)} className=' text-[10px] bg-red-500 text-white h-[20px] px-3  rounded-full'>حذف</button>

                            </>
                                : <p className='h-[20px] text-[12px] text-green-500'>منفذ</p>}

                        </div>
                    </div>



                </div>
            </div>
        </>
    );
};


const TransactionDetails = ({ transaction, fetchTransactions, close }) => {


    const service = new GeneralServices()

    // عدد مرات التحويل
    const [TransactionData, setTransactionData] = useState({
        transaction_id: transaction?.id,
        count: 1
    })


    const { setFetch } = useContext(AuthContext);


    const handleAprove = async (id) => {

        try {
            const res = await service.post(`transaction/approve`, TransactionData)
            fetchTransactions(1)
            close()
            showSuccessToast('تم التحويل بنجاح')
        } catch (error) {
            console.log('error', error)
            showErrorToast('عفوا يوجد خطاء في عملية التحويل')
        }
    }
    return <div>
        <div>

            <p className='text-gray-400'>المبلغ المحول  </p>
            <Input
                onChange={() => { }}
                label="المبلغ"
                placeholder={"المبلغ"}
                value={transaction?.to_balance}
                disabled
            />
        </div>
        <div className='mt-2'>
            <p className='text-gray-400'>عدد مرات التحويل </p>
            <Input
                label="المبلغ"
                onChange={(e) => setTransactionData({ ...TransactionData, count: e.target.value })}
                value={TransactionData.count}
                placeholder={"عدد مرات التحويل "}

            />
        </div>
        {transaction.status === 0 ? <Button onClick={handleAprove} text={'تنفيذ'} className='border px-3  rounded-full' /> : <p>منفذ</p>}

    </div>;
};


export default TransactionCard;

// Helper function to format date (you can implement this as needed)
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};


const TransactionDate = ({ transaction }) => {
    const transactionDate = new Date(transaction?.created_at);
    const asiaDubaiDate = transactionDate.toLocaleString('en-US', { timeZone: 'Asia/Dubai' });
    return (
        <div>
            <p className='text-gray-500 font-medium'>{new Date(asiaDubaiDate).toLocaleDateString('en-KW', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
            <p className='text-gray-400 text-sm font-regular '>{new Date(asiaDubaiDate).toLocaleTimeString('en-KW', { hour: '2-digit', minute: '2-digit', hour12: true })}</p>
        </div>
    );
};
