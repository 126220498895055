import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BottomSheet from '../../../components/BottomSheet'
import EditUser from './EditUser'
import GeneralServices from '../../../services/GeneralServices'
import { showSuccessToast } from '../../../utils/toastUtils'

function UserCard({ data, fetchUsers }) {

    const [isEdit, setIsEdit] = useState(false)

    const [loading, setLoading] = useState(false)

    const service = new GeneralServices()

    const handleEdit = () => {
        setIsEdit(!isEdit)
    }

    const handleUpdateStatus = async () => {
        setLoading(true)
        try {
            const res = await service.post(`users/${data.id}/status`)
            showSuccessToast('تم تغيير حالة الموظف بنجاح')
            fetchUsers()
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }

    return (

        <>
            <BottomSheet isOpen={isEdit} onClose={() => setIsEdit(false)} title='تعديل موظف'>
                <EditUser close={handleEdit} fetchUsers={fetchUsers} userData={data} />
            </BottomSheet>
            <div className='flex gap-2 bg-white shadow-md p-2 m-2 rounded-lg justify-between items-center py-3'>
                <div className='flex gap-2 items-center'>
                    <img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' alt='' className='w-6 h-6' />
                    <div>
                        <p className='text-md font-medium'>{data.full_name}</p>
                        <div className='flex gap-2 items-center'>
                            <div className={`w-2 h-2 rounded-full cursor-pointer select-none ${data?.status == 1 ? ' bg-greenColor' : ' bg-rose-700'}`}></div>
                            <p onClick={handleUpdateStatus} className={`text-sm  select-none cursor-pointer  ${data?.status == 1 ? ' text-greenColor' : ' text-rose-700'}`}>
                                {data?.status == 1 ? 'نشط' : 'غير نشط'}

                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <Link className='text-blue-600 cursor-pointer' to={`/users/${data.id}`}>
                        <img className='w-[20px] h-[20px]' src='/assets/icons/eye.png' />
                    </Link>
                    <div className='text-blue-600 cursor-pointer' onClick={handleEdit} >
                        <img className='w-[20px] h-[20px]' src='/assets/icons/edit.png' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserCard