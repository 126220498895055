import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContextProvider'

function AppLayout() {

    const { user, setUser } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)


    const { pathname } = useLocation();


    const navigate = useNavigate()

    useEffect(() => {
        // setUser(null)
        // localStorage.removeItem('user')
        setLoading(true)

        const userData = JSON.parse(localStorage.getItem('user'))
        // console.log('userData', userData)
        if (!user) {
            if (userData) {
                setUser(userData)
            } else {
                navigate('/login')
            }
        }

        setLoading(false)
    }, [user])

    const userPaths = ['/transaction/list', '/expenses', '/settings']

    useEffect(() => {

        if (user && user?.user?.role !== 'admin' && !userPaths.includes(pathname)) {

            navigate('/transaction/list')
        }

        const userData = JSON.parse(localStorage.getItem('user'))

        if (!user) {

            navigate('/login')
        }
        if (userData == null) {
            setUser(null)
            navigate('/login')
        }



    }, [pathname])


    return (
        <div>

            <div className='mx-[12px]'>
                <Profile />
            </div>
            <div className=' mx-[12px]'>
                <Outlet />
                <div className='h-[100px]' />
            </div>

            {user && user?.user?.role === 'admin' ? <AdminNavbar /> : <UserNavbar />}

        </div>
    )
}



const Profile = () => {

    const { user } = JSON.parse(localStorage.getItem('user')) ?? []

    return (
        <div className='mt-[10px]'>
            <div className='flex gap-2'>
                <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="" className='w-[40px] h-[40px] rounded-full' />
                <div>
                    <p className='font-[700] text-lg'><small className='font-[700] text-lg text-gray-500' >مرحبا , </small> {user?.full_name.split(' ').slice(0, 1).join(' ')}</p>
                    <p className='text-sm text-[#2E7AB6]'>سويفت للتحويلات</p>
                </div>
            </div>
        </div>
    )
}

const AdminNavbar = () => {


    const { pathname } = useLocation();

    return (<div className='flex justify-between px-[12px] fixed bottom-0 w-full bg-ahmed h-[72px] text-white items-center' style={{
        backgroundColor: 'red !important'
    }}>

        <div>
            <Link className={`flex flex-col items-center ${pathname === '/wallet' && 'font-[700] text-gray-300'}`} to='/wallet'>
                <img src="/assets/nav/wallet.png" alt="wallet" className='w-[24px] h-[24px]' />
                <p>الرصيد</p>
            </Link>
        </div>

        <div>

            <Link className={`flex flex-col items-center  ${pathname === '/users' && 'font-[700] text-gray-300'}`} to='/users'>

                <img src="/assets/nav/user.png" alt="users" className='w-[24px] h-[24px]' />
                <p>المستخدمين</p>
            </Link>
        </div>

        <div>

            <Link className={`flex flex-col items-center mb-[50px]`} to='/transaction'>

                <img src="/assets/nav/transaction.png" alt="" className='w-[70px] h-[70px]' />
                <p className=''>تحويل</p>
            </Link>
        </div>

        <div>

            <Link className={`flex flex-col items-center  ${pathname === '/expenses' && 'font-[700] text-gray-300'}`} to='/expenses'>

                <img src="/assets/nav/expenses.png" alt="" className='w-[24px] h-[24px]' />
                <p>منصرفات</p>
            </Link>
        </div>

        <div>

            <Link className={`flex flex-col items-center  ${pathname === '/settings' && 'font-[700] text-gray-300'}`} to='/settings'>

                <img src="/assets/nav/report.png" alt="reports" className='w-[24px] h-[24px]' />
                <p>اعدادات</p>
            </Link>
        </div>

    </div>
    )
}

const UserNavbar = () => {


    const { pathname } = useLocation();


    return (<div className='flex justify-between px-[12px] fixed bottom-0 w-full bg-ahmed h-[72px] text-white items-center'>

        <div>
            <Link className={`flex flex-col items-center ${pathname === '/transaction/list' && 'font-[700] text-gray-300'}`} to='/transaction/list'>
                <img src="/assets/nav/wallet.png" alt="wallet" className='w-[24px] h-[24px]' />
                <p>التحويلات</p>
            </Link>
        </div>





        <div>

            <Link className={`flex flex-col items-center  ${pathname === '/expenses' && 'font-[700] text-gray-300'}`} to='/expenses'>

                <img src="/assets/nav/expenses.png" alt="" className='w-[24px] h-[24px]' />
                <p>منصرفات</p>
            </Link>
        </div>

        <div>

            <Link className={`flex flex-col items-center  ${pathname === '/settings' && 'font-[700] text-gray-300'}`} to='/settings'>

                <img src="/assets/nav/report.png" alt="reports" className='w-[24px] h-[24px]' />
                <p>اعدادات</p>
            </Link>
        </div>

    </div>
    )
}

export default AppLayout