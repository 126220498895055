import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import GeneralServices from '../../services/GeneralServices';
import TransactionCard from './Component/TransactionCard';
import { AuthContext } from '../../contexts/AuthContextProvider';

const PaginatedTransactionList = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const observer = useRef();
    const service = new GeneralServices();

    const lastTransactionElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);


    const { fetch, setFetch } = useContext(AuthContext);




    const fetchTransactions = async (page) => {
        if (page === 1) {
            setTransactions([]);
        }
        setIsLoading(true);
        try {
            const res = await service.get(`transaction?page=${page}`);
            setTransactions(prevTransactions => [...prevTransactions, ...res.data]);
            setHasMore(res.current_page < res.last_page);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        fetchTransactions(currentPage);
    }, [currentPage]);




    return (
        <div >
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-lg font-semibold">سجل التحويلات</h1>
                <div className={`flex gap-1 w-8 h-8 ${transactions.filter(transaction => transaction.status === 0).length > 0 ? 'bg-red-600' : 'bg-ahmed'} rounded-full items-center justify-center`}>
                    <p className="text-white">{transactions.filter(transaction => transaction.status === 0).length}</p>
                </div>
            </div>

            {transactions.map((transaction, index) => (
                <div className="w-full" key={transaction.id} ref={index === transactions.length - 1 ? lastTransactionElementRef : null}>
                    <TransactionCard
                        transaction={transaction}
                        fetchTransactions={() => fetchTransactions(currentPage)}
                    />
                </div>
            ))}

            {isLoading && <p className="text-center mt-4">جارى تحميل المعاملات...</p>}
            {!hasMore && transactions.length > 0 && (
                <p className="text-center mt-4">لا يوجد المزيد من المعاملات.</p>
            )}
            {!hasMore && transactions.length === 0 && (
                <p className="text-center mt-4">
                    قم بالتحقق من متصفحك والمحاولة مرة اخرى
                </p>
            )}
        </div>
    );
};

export default PaginatedTransactionList;