import React, { useEffect, useState } from 'react'
import UserCard from './Component/UserCard'
import BottomSheet from '../../components/BottomSheet'
import GeneralServices from '../../services/GeneralServices';
import AddUser from './Component/AddUser';


function UsersPage() {

    const [open, setOpen] = useState(false);

    const [users, setUsers] = useState([]);

    const service = new GeneralServices();


    const fetchUsers = async () => {
        try {
            const users = await service.get('users')
            setUsers(users)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleAddEmployee = () => {
        setOpen(false);
    }

    const close = () => setOpen(!open)

    useEffect(() => {
        fetchUsers()

    }, [])


    return (
        <>
            <BottomSheet title='اضافة موظف' isOpen={open} onClose={() => setOpen(false)} >
                <AddUser fetchUsers={fetchUsers} close={close} />

            </BottomSheet>
            <div>
                <div className='flex justify-between mx-2'>
                    <h1 className='text-lg font-semibold '>الموظفين</h1>
                    <div onClick={() => setOpen(true)} className='bg-black rounded-md text-white px-2 py-1 flex gap-2 select-none cursor-pointer'>
                        <img src='/assets/icons/add.png' alt='' className='w-6 h-6' />
                        <p >اضافة</p>
                    </div>
                </div>


                <div>

                    {/* print 10 cards */}
                    {!users.length && <div className='flex justify-center items-center h-[400px] '>
                        <p className='font-medium text-lg'>لايوجد موظفين</p></div>}
                    {users.map((item, index) => {
                        return (
                            <UserCard data={item} key={index} fetchUsers={fetchUsers} />
                        )
                    })}
                </div>
            </div>

        </>
    )
}


// التحويل من محفظه الى اخرى 

export default UsersPage