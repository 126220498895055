import React from 'react';

function Button({ onClick, text, loading, disabled, width = '100%', font = 'semibold', color = 'black' }) {
    return (
        <div className='flex justify-center mt-2'>
            <div
                onClick={!loading && !disabled ? onClick : undefined}
                className={`border cursor-pointer w-[80%] p-2 rounded-xl mt-2 ${disabled || loading ? 'opacity-50' : 'opacity-100'} text-center bg-${color} font-${font} text-white opacity-${disabled || loading ? 0.5 : 1}`}
                style={{ width, pointerEvents: disabled || loading ? 'none' : 'auto' }}
                disabled={disabled}
            >
                <p>
                    {loading ? 'جارى المعالجه ...' : text}
                </p>
            </div>
        </div>
    );
}

export default Button;

