import React, { useContext, useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BalanceCard from '../settings/Component/BalanceCard.';
import ListTile from '../../components/ListTile';
import { formatDate } from '../../utils/utility';
import Button from '../../components/Button';
import BottomSheet from '../../components/BottomSheet';
import Input from '../../components/Input';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';

function UserShowPage() {

    // get id form url
    const { id } = useParams();

    const [user, setUser] = useState({});

    const service = new GeneralServices();




    const navigate = useNavigate();



    const handleGetUser = async () => {
        try {
            const res = await service.get(`users/${id}`);
            setUser(res);
            console.log('user', user)
        } catch (error) {
            console.log(error);
        }
    }





    const tasb = [
        {
            title: 'الرصيد',
            component: <Balance user_id={id} />,
        },
        {
            title: 'الخصم والاضافة',
            component: <TopUp />
        },

        {
            title: 'التحويلات',
            component: <SafeTransfers user_id={id} />
        }
    ]

    const [activeTab, setActiveTab] = useState(tasb[0])

    const [open, setOpen] = useState(false);
    useEffect(() => {
        handleGetUser();
    }, [])

    return (
        <div>
            <BottomSheet title='تحويل من محفظه موظف' isOpen={open} onClose={() => setOpen(false)} >
                <TransferModal onClose={() => setOpen(false)} user_id={id} />
            </BottomSheet>
            {/* <div className=' items-center py-3'>
                <div className='flex flex-col items-center justify-center text-center mt-4 mb-8 text-xl font-bold text-gray-800'>
                    <img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' alt='' className='w-24 h-24 mb-4' />
                    <h1 className='text-md font-medium'>{user.full_name}</h1>
                    <div className='flex gap-2 items-center'>
                        <div className={`w-2 h-2 rounded-full ${user?.status == 1 ? ' bg-green-500' : ' bg-rose-500'}`}></div>
                        <p className='text-sm'>{user?.status == 1 ? 'نشط' : 'غير نشط'}</p>
                    </div>
                </div>
            </div> */}
            <hr className='my-2' />
            <div className='flex shadow-md p-2 rounded-md mb-2 bg-white justify-between items-center'>
                <div>
                    <p className='text-sm'>بيانات المستخدم </p>
                    <p className='font-bold'>{user.full_name}</p>
                </div>

                <div onClick={() => setOpen(true)} className='bg-black rounded-md text-white px-2 h-7 flex items-center gap-2 select-none cursor-pointer'>
                    <img src='/assets/icons/transfer.png' alt='' className='w-6 h-6' />
                    <p >تحويل</p>
                </div>
            </div>
            <div className='flex gap-2 w-full overflow-x-scroll py-2' >
                {tasb.map((tab, i) => (
                    <p key={i} className={` px-4 rounded-lg cursor-pointer ${activeTab.title == tab.title ? 'bg-black text-white' : 'bg-white'}`} style={{ minWidth: '100px' }} onClick={() => setActiveTab(tab)}>{tab.title}</p>
                ))}
            </div>
            <div>
            </div>
            {activeTab.component && activeTab.component}

        </div>
    )

}


// التحويل من محفظه الى اخرى 
const TransferModal = ({ user_id, onClose }) => {

    const [transferBalanceData, setTransferBalanceData] = useState({
        balance: null,
        note: null,
        safe_id: null,
    });
    const [validation, setValidation] = useState([]);

    const [safes, setSafes] = useState([]);

    const service = new GeneralServices();

    const navigate = useNavigate();


    const fetchSafes = async () => {
        try {
            const res = await service.get(`safes/my/${user_id}`);
            setSafes(res);
        } catch (error) {
            console.log(error);
        }
    }


    const handleTransferBalance = async () => {
        setValidation([]);
        try {
            const res = await service.post('safes/transfer_from_user', transferBalanceData);
            showSuccessToast(res.message);
            onClose();
            navigate(`/users/${user_id}`);
        } catch (error) {
            if (error.state == 400) {
                showErrorToast(error.message);
            }
            if (error.validationData) {
                setValidation(error.validationData);
            }
        }
    }


    useEffect(() => {
        fetchSafes()
    }, [])


    return <div>
        <div className='mt-2'>

            <Input
                placeholder={'ادخل المبلغ'}
                type={'number'}
                value={transferBalanceData.balance}
                onChange={(e) => setTransferBalanceData({ ...transferBalanceData, balance: e.target.value })}
            />

            {validation?.balance && <p className='text-[10px] m-1 text-rose-700'>{validation?.balance?.[0]}</p>}
        </div>
        <div className='mt-2'>
            <Input placeholder={'ملاحظات'} type={'text'} value={transferBalanceData.note} onChange={(e) => setTransferBalanceData({ ...transferBalanceData, note: e.target.value })} />

            {validation?.balance && <p className='text-[10px] m-1 text-rose-700'>{validation?.balance?.[0]}</p>}
        </div>
        <div className='mt-2'>
            {/* {<Select onChange={(e) => setTransferBalanceData({ ...transferBalanceData, safe_id: e.target.value })} options={safes.map((safe) => ({ value: safe.id, label: safe.name }))} />} */}

            <div className='flex gap-2'>
                {safes.map(safe => <p key={safe.id} onClick={() => setTransferBalanceData({ ...transferBalanceData, safe_id: safe.id })} className={`${safe.id == transferBalanceData.safe_id ? 'bg-black text-white' : 'bg-white border'} px-2 h-7 flex items-center gap-2 select-none cursor-pointer rounded-full`}>{safe.name}</p>)}
            </div>
        </div>
        <div className='mt-2'>
            <Button onClick={handleTransferBalance} text={'تحويل'} />
        </div>
    </div>
}

const TopUp = () => {

    const [tops, setTops] = useState([])

    const service = new GeneralServices();

    const { id } = useParams()

    const fetchTopUps = async () => {
        try {
            const res = await service.get(`top_ups/${id}`)

            setTops(res)

        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        fetchTopUps()
    }, [])


    return <div>
        {tops.map(top => <div className='flex mt-2 shadow-md bg-white p-2 items-center justify-between'>
            <div className='flex justify-center items-center gap-1'>
                <div className={`flex justify-center items-center w-[40px] h-[40px] rounded-full ${top.type == 'add' ? 'bg-green-50' : 'bg-red-50'}`}>
                    <img width="24" height="24" src={top.type !== 'add' ? 'https://img.icons8.com/ios-filled/50/F25081/long-arrow-up.png' : 'https://img.icons8.com/ios-filled/50/40C057/long-arrow-down.png'} alt="long-arrow-up" />

                </div>
                <div> <p className='mx-2'>
                    {/* <p>{JSON.stringify(top)}</p> */}
                    <p className='gap-2'>{top.balance} {top?.currency?.short_name}</p>
                    {/* <p>{top.created_at}</p> */}
                </p>


                    <p className='text-sm text-gray-400'>{top.note}</p>

                </div>
            </div>

            <div> <p className='text-sm text-gray-400'>{formatDate(top.created_at)}</p>

                <p className='text-sm text-gray-400'>{top?.safe?.name}</p>
            </div>


        </div>)}
    </div>
}


const Balance = ({ user_id }) => {




    const [safes, setSafes] = useState([])

    const [balances, setBalances] = useState([])
    const [labels, setLabels] = useState([])

    const service = new GeneralServices();

    const fetchMySafes = async () => {
        try {
            const res = await service.get(`safes/my/${user_id}`)

            setSafes(res)

            const b = res.map(safe => safe.balance)

            const l = res.map(safe => safe.name)

            setLabels(l)

            setBalances(b)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        fetchMySafes()
    }, [])


    return <BalanceCard safes={safes} balances={balances} labels={labels} />
}


const SafeTransfers = ({ user_id }) => {

    const [transactions, setTransactions] = useState([])

    const service = new GeneralServices();


    const fetchTransactions = async () => {
        try {
            const res = await service.get(`safe_transfers/${user_id}`)
            setTransactions(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        fetchTransactions()
    }, [])


    return (
        <div>
            {transactions.map(transactions => <div className='flex mt-2 shadow-md bg-white p-2 items-center justify-between'>
                <div className='flex justify-center items-center gap-1'>
                    <div className={`flex justify-center items-center w-[40px] h-[40px] rounded-full ${transactions?.from_safe?.user_id == user_id ? 'bg-red-50' : 'bg-green-50'}`}>
                        <img width="24" height="24" src={transactions?.from_safe?.user_id == user_id ? 'https://img.icons8.com/ios-filled/50/F25081/long-arrow-up.png' : 'https://img.icons8.com/ios-filled/50/40C057/long-arrow-down.png'} alt="long-arrow-up" />

                    </div>
                    <div> <p className='mx-2'>
                        {/* <p>{JSON.stringify(transactions)}</p> */}
                        <p className='gap-2'>{transactions.balance} {transactions?.currency?.short_name}</p>
                        {/* <p>{transactions.created_at}</p> */}
                    </p>


                        <p className='text-sm text-gray-400'>{transactions.note}</p>

                    </div>
                </div>

                <div> <p className='text-sm text-gray-400 text-right'>{formatDate(transactions.created_at)}</p>

                    <p className='text-sm text-gray-400'>
                        {transactions?.from_safe?.user_id !== user_id && transactions?.from_safe?.name}

                    </p>
                </div>


            </div>)}
        </div>
    )

}



export default UserShowPage