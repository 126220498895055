import React, { useContext, useEffect, useState } from 'react'
import BottomSheet from '../../../components/BottomSheet';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import GeneralServices from '../../../services/GeneralServices';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { Link } from 'react-router-dom';
import { formatNumber, formatRupee } from '../../../utils/utility';
import { AuthContext } from '../../../contexts/AuthContextProvider';

function WalletCard({ data }) {

    const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);


    const openBottomSheet = () => setBottomSheetOpen(true);
    const closeBottomSheet = () => setBottomSheetOpen(false);

    return (
        <>

            <BottomSheet isOpen={isBottomSheetOpen} onClose={closeBottomSheet}>
                <WalletCardOptions data={data} close={closeBottomSheet} />
            </BottomSheet>


            <div className={` shadow-md p-2 mt-2 text-white px-2  rounded-2xl`} style={{ backgroundColor: data.color }}>

                <div>
                    <div className='flex justify-between '>
                        <div className='flex gap-2 items-center'>
                            <img src={data.icon} alt="" className='w-[30px] h-[30px] rounded-full' />
                            <p className='font-bold'>{data.name}</p>
                            <p className='text-sm'>{data.currency}</p>
                        </div>
                        <img onClick={openBottomSheet} className='w-[20px] h-[20px] cursor-pointer' src='/assets/icons/arrow.png' />
                    </div>
                    <div className='mt-[20px]' />
                    <div className='items-end'>

                        <div className='flex gap-2 items-baseline'>
                            <span>{data.symbol}</span>
                            <p className='text-[25px] font-bold'> {formatRupee(data.balance, data.symbol)}</p>
                        </div>
                        <span className='text-sm'>الرصيد الحالى</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const WalletCardOptions = ({ close, data }) => {



    const [addWalletOpen, setAddWalletOpen] = useState(false)
    const [transferWalletOpen, setTransferWalletOpen] = useState(false)

    const [TopUpType, setTopUpType] = useState('')

    const openModel = (type) => {
        setTopUpType(type)
        setAddWalletOpen(true)
    }






    return (
        <>
            {addWalletOpen && <AddWallet type={TopUpType} data={data} isOpen={addWalletOpen} setIsOpen={setAddWalletOpen} />}
            {transferWalletOpen && <TransferWallet title="تحويل الى خزنه" data={data} isOpen={transferWalletOpen} setIsOpen={setTransferWalletOpen} />}

            <div onClick={() => openModel('add')} className='flex mt-3 gap-2 items-center cursor-pointer'>
                <img src="/assets/icons/AddTag.png" alt="" className='w-[30px] h-[30px] rounded-full' />
                <p>اضافة رصيد</p>
            </div>
            <div onClick={() => openModel('deduct')} className='flex mt-3 gap-2 items-center cursor-pointer'>
                <img src="/assets/icons/AddTag.png" alt="" className='w-[30px] h-[30px] rounded-full' />
                <p>خصم رصيد</p>
            </div>

            <div onClick={() => setTransferWalletOpen(true)} className='flex mt-3 gap-2 items-center cursor-pointer'>
                <img src="/assets/icons/PriceTag.png" alt="" className='w-[30px] h-[30px] rounded-full' />
                <p>تحويل الى محفظة</p>
            </div>

            <Link to={`/wallet/transaction/${data.id}`} className='flex mt-3 gap-2 items-center cursor-pointer'>
                <img src="/assets/icons/HealthGraph.png" alt="" className='w-[30px] h-[30px] rounded-full' />
                <p>العمليات </p>
            </Link>
        </>
    )
}


// اضافة رصيد 
const AddWallet = ({ isOpen, setIsOpen, data, type }) => {


    const [validation, setValidation] = useState([]);
    const [loading, setLoading] = useState(false);

    const [addBalanceData, setAddBalanceData] = useState({
        balance: '',
        note: '',
        currency_id: data.id,
        type: type

    })

    const { setFetch, fetch } = useContext(AuthContext)






    const service = new GeneralServices;

    const handleAddBalance = async () => {


        setAddBalanceData({ ...addBalanceData, type: type })
        setValidation([])
        setLoading(true)
        try {
            const res = await service.post('currencies/add_balance', addBalanceData)
            showSuccessToast('تمت اضافة المبلغ بنجاح')
            setFetch('currency/add_balance')
            closeBottomSheet()
        } catch (error) {

            if (error.state == 400) {

                showErrorToast(error.message)
            }
            if (error.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            setLoading(false)
            setFetch('')
        }
    }

    const closeBottomSheet = () => {

        setIsOpen(false)
    }

    return <BottomSheet
        headerClass={type == 'add' ? 'bg-green' : 'bg-red'}
        isOpen={isOpen} onClose={closeBottomSheet} title={type == 'add' ? `اضافة : ${data.name}` : `خصم  : ${data.name}`}>

        <div className='mt-2'>
            <Input placeholder={'ادخل المبلغ'} type={'number'} onChange={(e) => setAddBalanceData({ ...addBalanceData, balance: e.target.value })} />

            {validation?.balance && <p className='text-[10px] m-1 text-rose-700'>{validation?.balance?.[0]}</p>}
        </div>

        <div className='mt-2'>
            <Input placeholder={'ملاحظات'} type={'text'} onChange={(e) => setAddBalanceData({ ...addBalanceData, note: e.target.value })} />

            {validation?.note && <p className='text-[10px] m-1 text-rose-700'>{validation?.note?.[0]}</p>}
        </div>

        <div className='mt-2'>
            <Button loading={loading} onClick={handleAddBalance} text={type == 'add' ? 'اضافة' : 'خصم'} />
        </div>
    </BottomSheet>
}


// اضافة رصيد 
const TransferWallet = ({ isOpen, setIsOpen, data }) => {


    const [validation, setValidation] = useState([]);
    const [safes, setSafes] = useState([])

    const [transferBalanceData, setTransferBalanceData] = useState({
        balance: '',
        note: '',
        safe_id: null,
    })

    const closeBottomSheet = () => setIsOpen(false)



    const service = new GeneralServices;

    const handleTransferBalance = async () => {

        setValidation([])
        try {
            const res = await service.post('safes/transfer', transferBalanceData)
            showSuccessToast(res.message)
            closeBottomSheet()
        } catch (error) {

            if (error.state == 400) {

                showErrorToast(error.message)
            }
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }

    const fetchSafes = async () => {
        try {
            const res = await service.get(`safes/${data.id}`)
            setSafes(res)
        } catch (error) {
            console.log('error', error)
        }
    }



    useEffect(() => {
        fetchSafes()

    }, [])

    return <BottomSheet isOpen={isOpen} onClose={closeBottomSheet} title={`تحويل من خزنه الى اخرى `}>
        <div className='mt-2'>
            <Input placeholder={'ادخل المبلغ'} type={'number'} onChange={(e) => setTransferBalanceData({ ...transferBalanceData, balance: e.target.value })} />

            {validation?.balance && <p className='text-[10px] m-1 text-rose-700'>{validation?.balance?.[0]}</p>}
        </div>

        <div className='mt-2'>
            <Input placeholder={'ملاحظات'} type={'text'} onChange={(e) => setTransferBalanceData({ ...transferBalanceData, note: e.target.value })} />

            {validation?.note && <p className='text-[10px] m-1 text-rose-700'>{validation?.note?.[0]}</p>}
        </div>

        <div className='flex overflow-x-scroll gap-2 p-2' style={{ scrollbarWidth: 'thin', scrollbarColor: 'gray transparent' }}>
            {safes.map((item, index) => (
                <p
                    key={index}
                    onClick={() => setTransferBalanceData({ ...transferBalanceData, safe_id: item.id })}
                    className={`cursor-pointer flex items-center gap-2 rounded-full px-2 py-1 ${transferBalanceData.safe_id === item.id ? 'text-white bg-black  ' : 'border px-2'}`}
                    style={{
                        whiteSpace: 'nowrap', // لضمان بقاء النص في سطر واحد
                        transition: 'all 0.3s ease', // لتحسين التفاعل البصري عند التغيير
                    }}
                >
                    <div className={`w-[10px] h-[10px] rounded-full ${item.warning_balance > item.balance ? 'bg-red-500' : 'bg-green-500'}`}></div>
                    {item.name}
                </p>
            ))}

            {validation?.safe_id && <p className='text-[10px] m-1 text-rose-700'>{validation?.safe_id?.[0]}</p>}

        </div>
        <div className='mt-2'>
            <Button onClick={handleTransferBalance} text={'اضافة'} />
        </div>
    </BottomSheet>
}
export default WalletCard