import React, { Component, useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import GeneralServices from '../../services/GeneralServices';
import { formatDate, formatNumber } from '../../utils/utility';
// import TransactionCard from './Components/TransactionCard';
import MyChart from '../../components/MyChart';

function WalletTransactionsPage() {

    // get id from url
    const { id } = useParams();

    const [currency, setCurrency] = useState({})

    const [filter, setFilter] = useState({
        currency_id: id,
        'range': null
    })

    const service = new GeneralServices()


    // fetch single currency
    const fetchCurrency = async () => {
        try {
            const res = await service.get(`currencies/${id}`, filter)
            setCurrency(res.currency)
            console.log('res', res)
        } catch (error) {
            console.log('error', error)
        }
    }


    const [activeTbab, setActiveTbab] = useState(1)

    const tabs = [
        {
            id: 1,
            name: 'تحويلات',
            component: <Transactions filter={filter} />
        },
        {
            id: 2,
            name: 'الخصم والاضافة',
            component: <TopUps filter={filter} />
        },
    ]

    useEffect(() => {
        // fetchTransactions()
        // fetchCurrencies()
        fetchCurrency()

    }, [])





    return (
        <>

            <div className='flex justify-between px-2'>
                <div className='flex gap-2 items-center'>
                    <img src={currency?.icon} className='w-6 h-6 rounded-full' alt='wallet' />
                    <p>{currency?.name}</p>
                </div>
                <div className='flex items-center gap-1'>
                    <p className='text-gray-300 select-none'>اليوم</p>
                </div>
            </div>
            <hr className='my-2' />
            <div className='flex gap-1'>
                {tabs.map((tab) => <p onClick={() => setActiveTbab(tab.id)} className={`text-gray-400 cursor-pointer select-none   rounded-full p-[1px] px-2 ${tab.id === activeTbab ? 'text-white bg-black' : 'text-gray-400 border'}`}>{tab.name}</p>)}
            </div>
            <hr className='my-2' />
            <div>
                {tabs[activeTbab - 1].component}
            </div>
            {/* <MyChart /> */}
            {/* <IncomExpensData /> */}
            {/* {transactions.map((transaction, index) => <TransactionCard index={index} transaction={transaction} />)} */}

        </>
    )
}

const TopUps = ({ filter }) => {


    const [tops, setTops] = useState([])

    const service = new GeneralServices()

    const fetchTops = async () => {
        try {
            const res = await service.post('top_ups', filter)
            setTops(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        fetchTops()
    }, [])


    const getSum = (type) => {
        if (type === 'add') {
            return tops.filter(top => top.type === 'add').reduce((sum, top) => sum + top.balance, 0)
        } else {
            return tops.filter(top => top.type !== 'add').reduce((sum, top) => sum + top.balance, 0)

        }
    }


    return <div>

        <div className='flex items-center justify-between shadow-md bg-white p-2 rounded-md'>
            <div className='flex gap-2 items-center'>
                <div className='bg-green-50 w-10 h-10 rounded-full flex justify-center items-center'>
                    <img className='w-6 h-6' src='https://img.icons8.com/ios-filled/50/40C057/long-arrow-down.png' />
                </div>

                <p className='text-gray-700 font-semibold text-xl'>{formatNumber(getSum('add'))}</p>

            </div>
            <div className='flex gap-2 items-center'>
                <div className='bg-red-50 w-10 h-10 rounded-full flex justify-center items-center'>
                    <img className='w-6 h-6' src='https://img.icons8.com/ios-filled/50/F25081/long-arrow-up.png' />
                </div>

                <p className='text-gray-700 font-semibold text-xl'>{formatNumber(getSum('decris'))}</p>

            </div>
        </div>
        {tops.map(top => <div className='flex mt-2 shadow-md bg-white p-2 items-center justify-between'>
            <div className='flex justify-center items-center gap-1'>
                <div className={`flex justify-center items-center w-[40px] h-[40px] rounded-full ${top.type == 'add' ? 'bg-green-50' : 'bg-red-50'}`}>
                    <img width="24" height="24" src={top.type !== 'add' ? 'https://img.icons8.com/ios-filled/50/F25081/long-arrow-up.png' : 'https://img.icons8.com/ios-filled/50/40C057/long-arrow-down.png'} alt="long-arrow-up" />

                </div>
                <div> <p className='mx-2'>
                    {/* <p>{JSON.stringify(top)}</p> */}
                    <p className='gap-2'>{top.balance} {top?.currency?.short_name}</p>
                    {/* <p>{top.created_at}</p> */}
                </p>


                    <p className='text-sm text-gray-400'>{top.note}</p>

                </div>
            </div>

            <div> <p className='text-sm text-gray-400'>{formatDate(top.created_at)}</p>

                <p className='text-sm text-gray-400'>{top?.safe?.name}</p>
            </div>


        </div>)}
    </div>
}

const Transactions = ({ filter }) => {

    const [transactionsData, setTransactions] = useState([])

    const service = new GeneralServices;


    const fetchTransactions = async () => {
        try {
            const res = await service.post('transactions', filter)
            console.log('res', res)

            setTransactions(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        fetchTransactions()

    }, [])

    return <TransactionCard transactions={transactionsData} />


}


const TransactionCard = ({ transactions }) => {

    return <>
        {
            transactions.map((transaction, index) => {
                return <div key={index} className='shadow-lg bg-white mt-2 rounded-xl p-2'>
                    <div className='flex justify-between'>
                        <div className='flex gap-2 items-center'>
                            <img src={transaction?.from_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                            <div>
                                <p>{transaction?.from_currency?.name}</p>
                                <p>{transaction?.from_balance}</p>
                                {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                            </div>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <img src={transaction?.to_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                            <div>
                                <p>{transaction?.to_currency?.name}</p>
                                <p className='text-[16px] font-bold'>{transaction?.to_balance}</p>
                                {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                            </div>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div>
                        <p className='text-gray-400'>{transaction?.note}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-between'>
                            <div className='flex gap-1 items-center'>
                                <div className='flex items-center gap-2'>
                                    <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/12B886/administrator-male--v1.png" alt="administrator-male--v1" />
                                    <p className='text-gray-500 '>بواسطه</p> <p className='text-gray-500 font-bold'>{transaction?.creator?.full_name.split(' ').slice(0, 2).join(' ')}</p>
                                </div>
                            </div>

                            <div className='flex gap-1 items-center '>
                                <div className='flex items-center gap-2'>
                                    <img width="24" height="24" src="https://img.icons8.com/material/24/12B886/administrator-male--v1.png" alt="administrator-male--v1" />

                                    <p className='text-gray-500 '>المنفذ</p> <p className='text-gray-500 font-bold'>{transaction?.approver?.full_name.split(' ').slice(0, 2).join(' ')}</p>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-between'>


                            <div className='flex gap-1 items-center '>
                                <div className='flex items-center gap-2'>
                                    <img width="24" height="24" src="https://img.icons8.com/pastel-glyph/64/12B886/calendar.png" alt="administrator-male--v1" />

                                    <p className='text-gray-500 font-bold'>{formatDate(transaction?.created_at)}</p>
                                </div>
                            </div>



                        </div>



                    </div>
                </div>
            })
        }
    </>


}



const IncomExpensData = () => {
    return <div className='rounded-xl mt-2 shadow-md p-2 flex justify-between py-3'>
        <div className='flex  items-center gap-1'>
            <div className='bg-[#ffad57] w-10 h-10 rounded-full flex justify-center items-center'>

                <p className='text-white'>△</p>
            </div>
            <div>
                <p className='text-md font-medium'>838373 SDG</p>
                <p className='text-gray-400'>الخارج</p>
            </div>
        </div>
        <div className='flex  items-center gap-1'>
            <div className='bg-[#4bd07f] w-10 h-10 rounded-full flex justify-center items-center'>

                <p className='text-white font-semibold'>▼</p>

            </div>
            <div>
                <p className='text-md font-medium'>838373 SDG</p>
                <p className='text-gray-400'>الدخل</p>
            </div>
        </div>

    </div>
}

export default WalletTransactionsPage